import { CardMedia, Stack, Typography } from "@mui/material";
import React from "react";
import { TranslateContext } from "../../../../../context/TranslateContext";

const WhatsCRM = ({ buttonDeploy }) => {
  const { data } = React.useContext(TranslateContext);
  return (
    <Stack direction={"column"} spacing={2}>
      <Typography variant="h4" align="center">
        {data.addOns}
      </Typography>
      <Stack direction={"row"} spacing={3} alignItems={"center"}>
        <Stack direction={"column"} spacing={1}>
          <Typography fontWeight={600} variant="body2">
            {data.AiChatbot}
          </Typography>
          <Stack
            sx={{ overflowX: "auto" }}
            direction={"row"}
            spacing={2}
            alignItems={"center"}
          >
            <CardMedia
              onClick={() =>
                window.open(
                  `https://codecanyon.net/item/ai-chat-for-whatsapp-addon-for-whatscrm/52498511`
                )
              }
              sx={{
                height: 60,
                width: 60,
                cursor: "pointer",
                borderRadius: 2,
              }}
              component={"img"}
              src="/assets/ai_chatbot_logo_512.png"
            />
          </Stack>
        </Stack>

        <Stack alignItems={"center"} direction={"column"} spacing={1}>
          <Typography fontWeight={600} variant="body2">
            {data.qrCodeLogin}
          </Typography>
          <Stack
            sx={{ overflowX: "auto" }}
            direction={"row"}
            spacing={2}
            alignItems={"center"}
          >
            <CardMedia
              onClick={() =>
                window.open(
                  `https://codecanyon.net/item/qr-code-login-for-whatsapp-addon-for-whatscrm/57356510`
                )
              }
              sx={{
                height: 60,
                width: 60,
                cursor: "pointer",
                borderRadius: 2,
              }}
              component={"img"}
              src="/assets/wacrm_qr_plugin_logo.png"
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default WhatsCRM;
