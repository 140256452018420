import React from "react";
import { Box, Typography, List, ListItem, ListItemIcon } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import { TranslateContext } from "../../../../../context/TranslateContext";

const WhatsCRMNotes = () => {
  const { data } = React.useContext(TranslateContext);
  const notes = [
    data.runNpm,
    data.restartApp,
    data.addonRestart,
    data.readdAddon,
    data.updateAppReq,
  ];

  return (
    <Box p={2} borderRadius={2} bgcolor="action.hover" boxShadow={1}>
      <Typography variant="subtitle1" fontWeight="bold" color="error">
        {data.impNotes}
      </Typography>
      <List dense>
        {notes.map((note, index) => (
          <ListItem key={index} sx={{ p: 0.5 }}>
            <ListItemIcon sx={{ minWidth: 30 }}>
              <WarningIcon fontSize="small" color="error" />
            </ListItemIcon>
            <Typography variant="body2">{note}</Typography>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default WhatsCRMNotes;
