import {
  Box,
  Button,
  CardMedia,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import {
  BrowserUpdatedOutlined,
  CalendarMonthOutlined,
  CloudSync,
  CopyAllOutlined,
  Language,
  OpenInNew,
} from "@mui/icons-material";
import moment from "moment";
import ActionButtonDall from "./ActionButtonDall";
import ActionButtonCRM from "./ActionButtonCRM";
import WhatsCRM from "./addons/WhatsCRM";
import ActionButtonWhatsHam from "./action/ActionButtonWhatsHam";
import WhatsHAMAddon from "./addons/WhatsHAMAddon";
import ActionButtonSonivo from "./action/ActionButtonSonivo";
import WhatsCRMNotes from "./notes/WhatsCRMNotes";

const ProductList = ({
  data,
  hitAxios,
  getProducts,
  products,
  i,
  key,
  setValue,
  buttonDeploy,
}) => {
  return (
    <div key={key}>
      <Box bgcolor={"action.hover"} borderRadius={4} p={2}>
        <Stack direction={"column"} spacing={2}>
          <Stack direction={"row"} spacing={2}>
            <CardMedia
              src={i?.product_logo}
              component={"img"}
              sx={{
                height: 90,
                width: 90,
                borderRadius: 2,
              }}
            />
            <Stack direction={"column"} spacing={0.6}>
              <Typography variant="body2">{i?.product_name}</Typography>
              <Stack direction={"row"}>
                <Tooltip title={data.purchaseCode}>
                  <code
                    style={{
                      display: "inline-block",
                      maxWidth: "100%", // Adjust the maximum width as needed
                      fontSize: 10,
                      backgroundColor: "rgba(0, 0, 0, 0.25)",
                      fontStyle: "italic",
                      padding: "0.2rem 0.4rem",
                      borderRadius: "4px",
                      color: "#fff",
                    }}
                  >
                    {i?.purchase_code}
                    <IconButton
                      onClick={() =>
                        window.navigator.clipboard.writeText(i?.purchase_code)
                      }
                      size="small"
                    >
                      <CopyAllOutlined
                        sx={{
                          height: 12,
                          width: 12,
                          color: "white",
                        }}
                      />
                    </IconButton>
                  </code>
                </Tooltip>
              </Stack>

              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <CalendarMonthOutlined sx={{ height: 16, width: 16 }} />
                <Typography variant="caption">
                  {moment(i?.purchase_date).format("DD MMM YY | hh:mmA")}
                </Typography>
              </Stack>

              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <Language sx={{ height: 16, width: 16 }} />
                <Stack direction={"row"} spacing={0.2} alignItems={"center"}>
                  <Typography variant="caption">
                    https://{i?.install_domain}
                  </Typography>

                  <IconButton
                    onClick={() => window.open(`https://${i?.install_domain}`)}
                    size="small"
                  >
                    <OpenInNew
                      sx={{
                        height: 12,
                        width: 12,
                      }}
                    />
                  </IconButton>
                </Stack>
              </Stack>
            </Stack>
          </Stack>

          {i?.product_id == 51122205 && <WhatsCRMNotes />}

          {/* whatscrm action  */}
          {i?.product_id == 51122205 && (
            <>
              <Divider />
              <ActionButtonCRM
                buttonDeploy={buttonDeploy}
                setValue={setValue}
                data={data}
                i={i}
                hitAxios={hitAxios}
              />
            </>
          )}
          {/* whatscrm addon  */}
          {i?.product_id == 51122205 && (
            <>
              <Divider />
              <WhatsCRM
                buttonDeploy={buttonDeploy}
                setValue={setValue}
                data={data}
                i={i}
                hitAxios={hitAxios}
              />
            </>
          )}
          {/* whatsham addon  */}
          {i?.product_id == 40727633 && (
            <>
              <Divider />
              <WhatsHAMAddon
                buttonDeploy={buttonDeploy}
                setValue={setValue}
                data={data}
                i={i}
                hitAxios={hitAxios}
              />
            </>
          )}
          {/* whatsham  */}
          {i?.product_id == 40727633 && (
            <>
              <Divider />
              <ActionButtonWhatsHam
                buttonDeploy={buttonDeploy}
                setValue={setValue}
                data={data}
                i={i}
                hitAxios={hitAxios}
              />
            </>
          )}
          {/* dall ham  */}
          {i?.product_id == 43648878 && (
            <>
              <Divider />
              <ActionButtonDall
                setValue={setValue}
                data={data}
                i={i}
                hitAxios={hitAxios}
              />
            </>
          )}
          {/* sonivo  */}
          {i?.product_id == 55533411 && (
            <>
              <Divider />
              <ActionButtonSonivo
                buttonDeploy={buttonDeploy}
                setValue={setValue}
                data={data}
                i={i}
                hitAxios={hitAxios}
              />
            </>
          )}
        </Stack>
      </Box>
    </div>
  );
};

export default ProductList;
