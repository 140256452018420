import { Box, Button, Stack, Typography } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import React from "react";
import { TranslateContext } from "../../../context/TranslateContext";

const BuyHostingButton = () => {
  const { data } = React.useContext(TranslateContext);
  const handleClick = () => {
    const phoneNumber = "918430088300"; // Replace with your WhatsApp number
    const message = encodeURIComponent(
      "I need recommended hosting with free installation for my app."
    );
    window.open(`https://wa.me/${phoneNumber}?text=${message}`, "_blank");
  };

  return (
    <Box p={3} borderRadius={3} bgcolor={"action.hover"} textAlign="center">
      <Typography fontWeight="bold" fontSize={18} color="primary">
        {data.recomHos}
      </Typography>

      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{ overflowX: "auto", mt: 1, py: 1 }}
        justifyContent={"center"}
      >
        {[data.freeAppIns, data.oneYrHos, data.sameRenewPrice].map(
          (item, index) => (
            <Typography
              key={index}
              p={1}
              bgcolor="action.hover"
              borderRadius={2}
              boxShadow={1}
              fontSize={14}
            >
              {item}
            </Typography>
          )
        )}
      </Stack>

      <Button
        variant="contained"
        startIcon={<WhatsAppIcon />}
        sx={{
          mt: 2,
          backgroundColor: "#25D366",
          "&:hover": { backgroundColor: "#1da851" },
        }}
        onClick={handleClick}
      >
        Buy Now
      </Button>
    </Box>
  );
};

export default BuyHostingButton;
